import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import router from './router'
import AuthService from './lib/auth.service';
// import './registerServiceWorker'



const clientID = process.env.VUE_APP_AD_CLIENT_ID;
const authUrl = process.env.VUE_APP_AD_AUTH_URL;
const redirect = process.env.VUE_APP_AD_REDIRECT;
const env = process.env.VUE_APP_ENV;




Vue.config.productionTip = false
Vue.prototype.$authService = new AuthService(clientID, authUrl, redirect, env);



Vue.mixin({
	data: () => ({
		loginFailed: false,
	}),

	methods: {
		login() {
			return new Promise((res, rej) => {

				this.$authService.login()
				.then( user => {
					if (user) {
						this.$store.commit('updateUser', user);

						this.$authService.getToken()
						.then( (token) => {
							this.$store.commit('updateToken', token);

							res(true);
						},
						(error) => {
							rej(false);
						});
					} else {
						this.loginFailed = true;
						rej(false);
					}
				},
				() => {
					this.loginFailed = true;
					rej(false);
				});
			})
		},


		errorLogin(err) {

			switch(err.response.status) {
				case 500:
					this.$store.commit("errorMsg", "There was an error with the system");
					break;

				case 400:
				case 404:
					this.$store.commit("errorMsg", `There was an error: ${err.response.data.errror.message}`);
					break;

				case 401:
					this.$store.commit("errorMsg", `Auth error: ${err.response.data.errror.message}`)
					this.$authService.login()
					.then( user => {
						if (user) {
							this.$store.commit('updateUser', user);

							this.$authService.getToken()
							.then( (token) => {
								this.$store.commit('updateToken', token);
								this.$router.go();
							},
							(error) => {
							});
						} else {
							this.loginFailed = true;
						}
					},
					() => {
						this.loginFailed = true;
					});
					break;

				default:
					this.$store.commit("errorMsg", "There was an error");
					break;
			}
		}
	}
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
