import axios from 'axios';
const host = process.env.VUE_APP_TELEDEX_API;

/**
* Request a list Schools and Departments
*/
export function getSchools() {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/departments/list`, 
		{
			headers: {
				'Accept': 'application/json'
			}
		})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
		
	})
}


/**
* Request a list of campusses from the API
*/
export function getCampuses() {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/campuses/list`, {
			headers: {
				'Accept': 'application/json'
			}})
			.then((response) => { res(response); })
			.catch((err) => { 
				rej(err); 
			})
	})
}


/**
* Request a list of campus buildings from the API
*/
export function getBuildings(campusCode) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/campuses/${campusCode}/buildings`, {
			headers: {
				'Accept': 'application/json'
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}



/**
* Request a list of campus building staff from the API
*/
export function getBuildingStaff(campusCode, buildingCode) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/staff/campuses/${campusCode}/buildings/${buildingCode}`, {
			headers: {
				'Accept': 'application/json'
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}



/**
* Request the details of a specific staff member
*/
export function getStaffDetails(staffID) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/staff/${staffID}`, {
			headers: {
				'Accept': 'application/json'
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}


/**
* Request a list of staff that are Justice of the Peace
*/
export function getJPStaff() {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/staff/jp`, {
			headers: {
				'Accept': 'application/json'
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}


/**
* Request a list of dialects interpreters speak from the API
*/
export function getDialects() {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/dialects/list`, {
			headers: {
				'Accept': 'application/json'
			}})
			.then((response) => { res(response); })
			.catch((err) => { 
				rej(err); 
			})
	})
}


/**
* Request a list of staff that are interpreters
*/
export function getInterpreters(dialect = null) {

	if (dialect == null) {
		return new Promise((res, rej) => {
			axios.get(`${host}/api/v1/staff/interpreters`, {
				headers: {
					'Accept': 'application/json'
				}})
				.then((response) => { res(response); })
				.catch((err) => { rej(err); })
		});

	} else {
		return new Promise((res, rej) => {
			axios.get(`${host}/api/v1/staff/dialect/${dialect}/interpreters`, {
				headers: {
					'Accept': 'application/json'
				}})
				.then((response) => { res(response); })
				.catch((err) => { rej(err); })
		});
	}	
}



/**
* Request a list of staff 
*/
export function getStaff(search, criteria) {
	return new Promise((res, rej) => {

		search = encodeURI(search);

		axios.get(`${host}/api/v1/staff/search/${criteria}/${search}`, {
			headers: {
				'Accept': 'application/json'
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej({msg: err.response.data.Error, server: err}); })
		
	})
}


/**
* Request a list of staff based on a school
*/
export function getSchoolStaff(unitCode) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/staff/department/${unitCode}`, {
			headers: {
				'Accept': 'application/json'
			}
		})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
		
	})
}