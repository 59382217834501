<template>

	<SearchModal heading="SEARCH FOR STAFF" :loading="$store.getters.loading">

		
        <form slot="form" class="form-row" @submit.prevent>
        	<v-container>
				<v-row align="center">
					<v-col cols="9" sm="4" md="3">
						<v-text-field
						  name="name"
						  label="Enter your search criteria"
						  id="id"
						  v-model="search"
						  required
						  autofocus
						></v-text-field>
					</v-col>

					<v-col cols="3" sm="2" >
						<v-btn 
                        type="submit"
						@click="requestStaff()"
						color="#903"
						width="100%">
							<v-icon color="white">mdi-magnify</v-icon>
						</v-btn>
					</v-col>

					<v-col cols="12" sm="6" md="7">
						<v-select
							:items="criteriaList"
							label="Select a search option"
							v-model="criteria"
							></v-select>
					</v-col>
				</v-row>
        	</v-container>
        	
        </form>
    </SearchModal>

</template>

<script>
	import SearchModal from "../components/layouts/SearchModal";
    import {getStaff} from "../lib/api";
    import {getPrivateStaff} from "../lib/private-api";
    import {debugMsg} from '../lib/helpers';

    export default {
        name: "home-page",
        components: {
            SearchModal
        },

        data: () => ({
            search: "",
            criteria: "name",
            criteriaList: [
                {text: "Anywhere in a person's name for...", value:"name"},
                {text: "For staff whose surname is...", value:"surname"},
                {text: "For staff whose first name is...", value:"firstname"},
                {text: "For Surnames sounding like...", value:"surname-sounds-like"},
                {text: "For staff at a campus...", value:"campus"},
                {text: "For records containing the following text...", value:"contains"},
                {text: "For Departments/Units/Colleges/Schools whose name contains...", value:"department"},
            ]
        }),

        methods: {
            /**
            * Request a list of availible campuses from the Staff directory API
            */
            requestStaff: function() {
                this.$store.commit('setLoading', true);


                // if logged in hit private API
                if (this.$store.getters.isLoggedIn) {
                    
                    // get valid token then request details
                    this.$authService.getToken()                
                        .then( (accessToken) => {

                            getPrivateStaff(accessToken, this.search, this.criteria)
                                .then((res) => {
                                    this.successSearch(res);
                                })
                                .catch((err) => {
                                    this.errorSearch(err);
                                });
                        },
                        (error) => {                            
                            this.$store.commit("errorMsg", `There was an error retrieving results`);

                        });
                       
                } else {
                    // if not logged in hit public API
                    getStaff(this.search, this.criteria)
                        .then((res) => {
                            this.successSearch(res);
                        })
                        .catch((err) => {
                            this.errorSearch(err);
                        })
                }
            },

            /**
            * Handle a successful search response from the API
            */
            successSearch: function(res) {
                this.$store.commit('setLoading', false);

                // Check if API returned any results
                if (res.data.data.length == 0) {
                    this.$store.commit("errorMsg", "No results were found");
                } else {
                    // store result in vuex
                    this.$store.commit("updateStaff", res);

                    this.$router.push({path: `/search/${this.criteria}/${encodeURI(this.search)}`})
                }
            },

            /**
            * Handle a successful search response from the API
            */
            errorSearch: function(err) {
                // display error message
                this.$store.commit('setLoading', false);
                this.$store.commit("errorMsg", `There was an error retrieving results`);

                this.errorLogin(err);
            },
        },
    }

</script>