const DEBUG_MSG = false;

/**
* Debug MEssages
*/
export function debugMsg(str) {
	if (DEBUG_MSG) {
		console.log(str)
	}
}


/**
* Check if a field is empty (Null or Undefined)
*
* @param field {String}: the field to check if empty
* @return {Boolean}: True if empty | False otherwise
*/
export function isEmpty(field) {
	return (field == undefined || field == null || field == '' || (Array.isArray(field) && field.length == 0));
}



/**
* Returns the extension from a DB recieved Extension
*/
export function getExtension( ext ) {
	var extension = [];

	ext = ext.trim();

	if( ext.length <= 4 ) {

		return ext;
	} else if ( ext.indexOf('&') > 0 ) {

		extension.concat(ext.split('&'));
	} else if( ext.indexOf('/') > 0 ) {

		extension.concat(ext.split('/'));
	} else {
		return ext;
	}

	if( extension.length > 0 ) {
		extension.forEach((e) => e = e.trim());
		return extension.join(', ');
	}
	
}



/**
*
*/
export function escapeRegExp(str) {
	if (!isEmpty(str)) {
		return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	}

	return "";
}


export function validateNumber(num) {
	const phReg = /((\d.*){7}\d|(\d.*){3}\d|(\d.*){9}\d)/g
	return phReg.test(num)
}



/**
* Return the title for a staff card, either position or name
*/
export function getTitle(staff) {
	if (!isEmpty(staff["First Names"]) && !isEmpty(staff["Surname"])) {
		// Name as title
		if (!isEmpty(staff['Title'])) {
			return `${staff['Title']} ${staff['First Names']} ${staff['Surname']}`
		}

		return `${staff['First Names']} ${staff['Surname']}`

	} else if (!isEmpty(staff["Position"])) {
		// Position as Title
		return staff["Position"];

	}

	// No title
	return "";
}



/**
* Return a staff members second languages
*/
export function showLang(staff) {

	if (Array.isArray(staff["lang"]) && staff["lang"].length > 0) {
		var lang = staff["lang"].map((lang) => {
			return lang["Dialect"];
		}).join(", ");

		debugMsg(lang);
		return lang
	}

	debugMsg(staff["lang"])

	return staff["lang"]
}