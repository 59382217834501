import Vue from 'vue'
import Vuex from 'vuex'
import {debugMsg} from '../lib/helpers';
import UserModule from './user';


Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		campuses: [],
		buildings: [],
		staffList: [],
		tempList: [],
		details: {},
		staffMember: {},
		schools: [],
		snackbar: false,
		snackMsg: "",
		snackColor: "error",
		back: false,
		onPremise: false,
		drawer: true,
		search: "",
		criteria: "",
		loading: false,
	},


	getters: {
		
		campuses(state) {
			return state.campuses;
		},

		buildings(state) {
			return state.buildings;
		},

		staff(state) {
			return state.staffList;
		},

		staffMember(state) {
			return state.staffMember;
		},

		temp(state) {
			return state.tempList;
		},

		details(state) {
			return state.details;
		},

		schools(state) {
			return state.schools;
		},

		snackbarMsg(state) {
			return state.snackMsg;
		},

		showSnackbar(state) {
			return state.snackbar;
		},

		snackbarColor(state) {
			return state.snackColor;
		},

		back(state) {
			return state.back;
		},

		onPremise(state) {
			return state.onPremise;
		},

		drawer(state) {
			return state.drawer;
		},

		loading(state) {
			return state.loading;
		}
	},


	mutations: {
		

		/* Update Local Schools */
		updateSchools(state, payload) {
			debugMsg("... Updating School ...");
			debugMsg(payload);
			state.schools = Array.from(payload.data.data);

			debugMsg("... Schools Updated Successfully!")
		},


		/* Update Local Campuses */
		updateCampuses(state, payload) {
			debugMsg("... Updating Campus ...");
			debugMsg(payload);
			state.campuses = Array.from(payload.data.data);

			debugMsg("... Campuses Updated Successfully!")
		},


		/* Update Local Campus Buildings */
		updateBuildings(state, payload) {
			debugMsg("... Updating Buildings ...");
			debugMsg(payload);
			state.buildings = Array.from(payload.data.data);

			debugMsg("... Campus Buildings Updated Successfully!")
		},


		/* Update Local Staff List */
		updateTemp(state, payload) {
			debugMsg("... Updating Temp List ...");
			debugMsg(payload);
			state.tempList = Array.from(payload);

			debugMsg("... Temp List Updated Successfully!")
		},

		/* Update Local Staff List */
		updateStaff(state, payload) {
			debugMsg("... Updating Staff List ...");
			debugMsg(payload);
			state.staffList = Array.from(payload.data.data);

			debugMsg("... Staff List Updated Successfully!")
		},

		/* Update Local Staff List */
		updateStaffMember(state, payload) {
			debugMsg("... Updating Staff Member ...");
			debugMsg(payload);
			state.staffMember = Object.assign({}, payload);
			state.onPremise = (state.staffMember["is_on_premise"] === true);

			debugMsg(`is on premise ${(state.staffMember["is_on_premise"] === true)}`)

			debugMsg("... Staff Member Updated Successfully!")
		},


		/* Update Local Staff List */
		updateDetails(state, payload) {
			debugMsg("... Updating Details ...");
			debugMsg(payload);
			state.details = Object.assign({}, payload.data.data);

			debugMsg("... Staff Details Updated Successfully!")
		},

		updateDrawer(state, payload) {
			state.drawer = !!payload
		},


		/* Update Local Staff List */
		errorMsg(state, payload) {
			debugMsg("... Updating Error Message ...");
			debugMsg(payload);
			state.snackMsg = payload;
			state.snackbar = true;
			state.snackColor = "error";

			debugMsg("... Error message updated successfully!")
		},


		successMsg(state, payload) {
			debugMsg("... Updating Error Message ...");
			debugMsg(payload);
			state.snackMsg = payload;
			state.snackbar = true;
			state.snackColor = "success";

			debugMsg("... Error message updated successfully!")
		},

		toggleSnackbar(state, payload) {
			debugMsg("... Toggling Snackbar ...");
			debugMsg(payload);
			state.snackbar = payload;

			debugMsg("... Snackbar Toggled Successfully!")
		},


		updateSnackbarColor(state, payload) {
			debugMsg("... Updating Snackbar Color ...");
			debugMsg(payload);
			state.snackColor = payload.trim();

			debugMsg("... Snackbar Message Color Successfully!")
		},


		/* Update Local Staff List */
		updateBack(state, payload) {
			debugMsg("... Updating Back ...");
			debugMsg(payload);
			state.back = payload;

			debugMsg("... Backupdated successfully!")
		},

		setDrawer(state, payload) {
			state.drawer = !!payload;
		},


		setLoading(state, payload) {
			state.loading = !!payload;
		}
	},
	actions: {

		/* Update Local Schools*/
		updateSchools(context) {
			debugMsg("... Updating the Schools ...");
			context.commit("updateSchools");
		},


		/* Update Local Campuses*/
		updateCampuses(context) {
			debugMsg("... Updating the Campuses ...");
			context.commit("updateCampuses");
		},

		/* Update Local Campus Buildings */
		updateBuildings(context) {
			debugMsg("... Updating the Campus Buildings ...");
			context.commit("updateBuildings");
		},


		/* Update Local Staff  */
		updateStaff(context) {
			debugMsg("... Updating the Staff List ...");
			context.commit("updateStaff");
		},


		updateStaffMember(context) {
			debugMsg("... Updating the Staff List ...");
			context.commit("updateStaffMember");
		},


		updateTemp(context) {
			debugMsg("... Updating the Staff List ...");
			context.commit("updateTemp");
		},


		/* Update Local Staff Details */
		updateDetails(context) {
			debugMsg("... Updating the Staff Details ...");
			context.commit("updateDetails");
		},

		/* Update Local Staff Details */
		updateDrawer(context) {
			debugMsg("... Updating theDrawer ...");
			context.commit("updateDrawer");
		},



		/* Update Local Staff Details */
		errorMsg(context) {
			debugMsg("... Updating toast with error message ...");
			context.commit("errorMsg");
		},

		updateBack(context) {
			debugMsg("... Updating back ...");
			context.commit("updateBack");
		},

		setDrawer(context) {
			debugMsg("... Updating drawer ...");
			context.commit("setDrawer");
		},

		setLoading(context) {
			debugMsg("... Updating loading flag ...");
			context.commit("setLoading");
		}
	},
	modules: {
		user: UserModule
	}
})
