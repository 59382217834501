<style scoped>

.v-list-group > * {
	padding: 0px 0px !important;
}

a {
  color: #903 !important;
  text-decoration: none;
}
</style>

<template>
	<v-navigation-drawer
		v-model="drawer"
		:clipped="$vuetify.breakpoint.lgAndUp"
		app
		>
		<v-list dense>
		<v-list-item class="text-center">

        <v-list-item-title>
			
			<a href="https://directory.westernsydney.edu.au" target="_blank">
				STAFF DIRECTORY
			</a>
		</v-list-item-title>

      </v-list-item>

      <v-divider></v-divider>
			<template v-for="(item, i) in links">

				<!-- If single menu item -->
				<v-list-item v-if="item.text"
					:key="item.text"
					:to="(item.url)?item.url:''"
					:href="(item.href)?item.href:''"
					:target="(item.href)?'_blank':''"
					@click="fire(item.click)">
					
					<v-list-item-action>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-action>
					<v-list-item-content>
						<v-list-item-title>
						{{ item.text }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<!-- if there should be a divider -->
				<v-divider v-if="item.divider" :key="i"></v-divider>
			</template>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "app-draw",
		props: [
			"links",
		],
		watch: {
		},

		computed: {
			drawer: {
				get() {
					return (
						this.$store.getters.drawer
					);
				},

				set (value) {
					this.$store.commit("updateDrawer", value);
				}
			}
		},

		methods: {
			fire: function(method) {
				switch(method) {
					case "login":
						this.login();
						break;
					case "logout":
						this.$store.commit('logout');
						break;
					default:
						console.log(method)
				}
			},
		}

	}
</script>