import Vue from 'vue'
import Vuex from 'vuex'
import {debugMsg} from '../lib/helpers';
import {getLocalUser, getLocalToken} from '../lib/private-api';

var user = {};
var token = "";

if (Vue.prototype.$authService) {
	if (Vue.prototype.$authService.msalObj) {
		user = Vue.prototype.$authService.msalObj.getAccount();
	}

	Vue.prototype.$authService.getToken()	            
	    .then( (accessToken) => {
			token = accessToken;
		},
		(error) => {
		});
}


Vue.use(Vuex)

export default {

	state: {
		user: user,
		token: token, 
	},


	getters: {
		isLoggedIn(state) {
			if (! (!!state.token)) {

				Vue.prototype.$authService.getToken()	            
				    .then( (accessToken) => {
						state.token = accessToken;
					},
					(error) => {
					});
			}

			return !!state.token;
		},


		user(state) {
			if (! (!!state.user)) {
				state.user = Vue.prototype.$authService.msalObj.getAccount();
			}

			return state.user;
		},


		token(state) {

			if (! (!!state.token)) {
				Vue.prototype.$authService.getToken()	            
				    .then( (accessToken) => {
						state.token = accessToken;
					},
					(error) => {
					});
			}
			
			return state.token;
		},
	},


	mutations: {
		updateUser(state, payload) {
			state.user = payload;
		},

		updateToken(state, payload) {
			state.token = payload;
		},

		logout(state) {
			state.user = false;
			state.token = false;
			Vue.prototype.$authService.logout();
		},
	},


	actions: {

		updateUser(context) {
			debugMsg("... Updating the User ...");
			context.commit("updateUser");
		},

		updateToken(context) {
			debugMsg("... Updating the Token ...");
			context.commit("updateToken");
		},

		logout(context) {
			context.commit("logout");
		},
	}
}
