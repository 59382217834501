/**
* For a list of Microsoft terminology descriptions visit:
* https://docs.microsoft.com/en-us/azure/active-directory/fundamentals/active-directory-whatis
*/
import * as Msal from 'msal';


/**
* The below class creates an instance of the MSAL to handle logins and token 
* acquisition
*/
export default class AuthService {

    constructor(client, auth, redirect, env) {
        /**
        * See the below link for configuration details & descriptions
        * https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-js-initializing-client-applications
        */
        this.msalConfig = {
            auth: {
                clientId: client,
                authority: auth,
                validateAuthority: false,
                redirectUri: redirect
            },

            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
            }
        };


        this.msalObj = new Msal.UserAgentApplication(this.msalConfig);

        this.scopeRequest = {
           scopes: ["user.read"],
           prompt: "select_account",
        };


        this.validMail = (env == 'prod') ? /@(city\.|student\.)?(westernsydney|uws)\.edu\.au$/gi : /(westernsydney|uws)\.edu\.au$/gi;
    }


    login() {
        return this.msalObj.loginPopup(this.scopeRequest).then(
            idToken => {
                const user = this.msalObj.getAccount();
                if (user) {

                    if (user.userName.match(this.validMail) !== null) {
                       return user;
                    }
                    else
                    {
                        this.logout();
                    }
                } else {
                    return null;
                }
            },
            () => {
                return null;
            }
        );
    };


    logout() {
        this.msalObj.logout();
    };


    getToken() {
        return this.msalObj.acquireTokenSilent(this.scopeRequest)
            .then(response  => {
                    // get access token from response
                    // response.accessToken
                    return response.accessToken;
            })
            .catch(error => {
                if (error.name === "InteractionRequiredAuthError") {

                    return this.msalObj.acquireTokenPopup(this.scopeRequest)
                        .then(response => {
                            // get access token from response
                            // response.accessToken
                            return response.accessToken;
                        })
                        .catch(err => {

                            //if user login error trigger login
                            if (String(err).match(/user_login_error/) != null) {
                                this.login();
                            }
                        });
                }
            });
    };
}
