<style scoped>

.navbar-brand {
	text-decoration: none;
	border-bottom: none;
	color: #FFF !important;
}

img {
	vertical-align: middle;
}

/* Doesnt work [Meant to fix caret in search input] */
.v-text-field--solo-inverted.v-input--is-focused > .v-input__control>.v-input__slot input {

  caret-color: #000 !important;
}
</style>

<template>
	<v-app-bar
		:clipped-left="$vuetify.breakpoint.lgAndUp"
		app
		color="#903"
		dark>

		<v-app-bar-nav-icon v-if="$route.path.match(/secure/gi) == null"
			@click.stop="$store.commit('setDrawer', !$store.getters.drawer)"
			v-model="drawer">
		</v-app-bar-nav-icon>

		<v-toolbar-title
			class="ml-0 pl-4">



			<a v-show="!hide && $vuetify.breakpoint.smAndUp" class="navbar-brand align-right" href="https://www.westernsydney.edu.au/">
				<img :src="`${publicPath}/img/Logo_LeftAligned_Reverse.png`" height="30px" alt="">
			</a>
			<!-- <div v-if="$vuetify.breakpoint.lgAndUp"
				class="d-inline">
				<router-link v-if="!hide && ($vuetify.breakpoint.mdAndUp && $vuetify.breakpoint.lgAndUp)"  
					to="/" 
					class="navbar-brand align-right ml-3 app-name">STAFF DIRECTORY</router-link>

				<span v-show="hide" class="navbar-brand align-right">
					<img src="img/Logo_LeftAligned_Reverse.png" height="30px" alt="">
					<span class="ml-3 app-name">STAFF DIRECTORY</span>
				</span>
			</div> -->
		</v-toolbar-title>

		<v-spacer></v-spacer>

		<!-- Search Bar -->
			<!-- :label="`Search ${criteria.charAt(0).toUpperCase()}${criteria.slice(1)}`" -->
			
		<v-text-field
			v-if= "notHomePage()"
			solo-inverted
			clearable
			dense
			outlined
			hide-details
			color="#903"
			prepend-inner-icon="mdi-magnify"
			:label="`SEARCH ALL RECORDS`"
			v-model="search"
			:loading="($store.getters.loading) ? 'red' : false"
			@keyup="enterPressSearch"
			class="mx-1">
				
			<template v-slot:append>
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn
						:text="$vuetify.breakpoint.mdAndUp"
						:icon="$vuetify.breakpoint.smAndDown"
						small
						color="#990033"
						v-on="on"
						>
						<v-icon :left="$vuetify.breakpoint.mdAndUp"
							small>mdi-filter</v-icon>
						{{ ($vuetify.breakpoint.mdAndUp) ? criteria : '' }}
						</v-btn>
					</template>
					<v-list>
						<v-list-item
						v-for="(item, index) in criteriaList"
						:key="index"
						@click="criteria = item.value"
						>
							<v-list-item-title>{{ item.text}}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</template>
				
		</v-text-field>

		<v-btn 
			icon 
			elevation="2"
			v-if= "notHomePage()"
			@click="requestStaff">
			<v-icon>mdi-magnify</v-icon>
		</v-btn>

		<v-spacer></v-spacer>

		
		<div fluid v-if="$vuetify.breakpoint.lgAndUp && 
					$route.path.match(/secure/gi) == null">

			<v-btn v-for="link in links"
				:icon="(!link.showText)"
				:key="link.text"
				:to="('url' in link)?link.url:''"
				:href="('href' in link)?link.href:''"
				:target="('href' in link)?'_blank':''"
				:small="(link.small)"
				:text="(link.showText)"
				@click="fire(link.click)">


				<v-icon :small="link.small" :left="link.showText">{{link.icon}}</v-icon>
				<span v-if="link.showText">{{link.text}}</span>


			</v-btn>			
		</div>
	</v-app-bar>
</template>

<script>

    import {getStaff} from "../../lib/api";
    import {getPrivateStaff} from "../../lib/private-api";
	export default {
		name: "app-header",
		props: ['links'],
		data: () => ({
			menu: true,
			hide: false,
			searchActive: false,
			user: {},
			showDropdown: false,
            staff_profile: process.env.VUE_APP_ADMIN_PROFILE,
			publicPath: process.env.VUE_APP_BASE_URL,

            search: "",
            criteria: "name",
            criteriaList: [
                {text: "Anywhere in a person's name for...", value:"name"},
                {text: "For staff whose surname is...", value:"surname"},
                {text: "For staff whose first name is...", value:"firstname"},
                {text: "For Surnames sounding like...", value:"surname-sounds-like"},
                {text: "For staff at a campus...", value:"campus"},
                {text: "For records containing the following text...", value:"contains"},
                {text: "For Departments/Units/Colleges/Schools whose name contains...", value:"department"},
            ]
		}),

		computed: {
			drawer: {
				get() {
					return (
						this.$store.getters.drawer
					);
				},

				set (value) {
					this.$store.commit("updateDrawer", value);
				}
			}
		},

		watch: {
			'$route' (to, from) {
				if (to != from) {
					this.menu = true;
				}

				if (to.path.match(/secure/g) != null) {
					this.hide = true;
				} else {
					this.hide = false;
				}

				// fix /search/ active link
				if (to.path.match(/search/g) != null) {
					this.searchActive = true;
				} else {
					this.searchActive = false;
				}
			},

		},


		mounted() {
			this.user = this.$store.getters.user;

			if (this.$route.path.match(/secure/g) != null) {
				this.hide = true;
			} else {
				this.hide = false;
			}


			// fix /search/ active link
			if (this.$route.path.match(/search/g) != null) {
				this.searchActive = true;
			} else {
				this.searchActive = false;
			}
		},


		methods: {

			notHomePage() {
				if(this.$route.path == "/" || this.$route.path == "/home" ) {
				return false
				} else {
				return true
				}
			},

			fire: function(method) {
				switch(method) {
					case "login":
						this.login();
						break;
					case "logout":
						this.$store.commit('logout');
						break;
					default:
						console.log(method)
				}
			},


			enterPressSearch: function(e) {
				// if logged in hit private API
				if (e.keyCode === 13) {
				    this.requestStaff();
				}
			},
		    /**
		    * Request a list of availible campuses from the Staff directory API
		    */
		    requestStaff: function() {
		        this.$store.commit('setLoading', true);
		        if (this.$store.getters.isLoggedIn) {
		            
		            // get valid token then request details
		            this.$authService.getToken()                
		                .then( (accessToken) => {

		                    getPrivateStaff(accessToken, this.search, this.criteria)
		                        .then((res) => {
		                            this.successSearch(res);
		                        })
		                        .catch((err) => {
		                            this.errorSearch(err);
		                        });
		                },
		                (error) => {
                			this.$store.commit("errorMsg", `Error searching for results`);
		                });
		               
		        } else {
		            // if not logged in hit public API
		            getStaff(this.search, this.criteria)
		                .then((res) => {
		                    this.successSearch(res);
		                })
		                .catch((err) => {
		                    this.errorSearch(err);
		                })
		        }
		    },

		    /**
		    * Handle a successful search response from the API
		    */
		    successSearch: function(res) {
		        this.$store.commit('setLoading', false);

		        // Check if API returned any results
		        if (res.data.data.length == 0) {
		            this.$store.commit("errorMsg", "No results were found");
		        } else {
		            // store result in vuex
		            this.$store.commit("updateStaff", res);

		            let path = `/search/${this.criteria}/${encodeURI(this.search)}`

		            if (this.$route.path !== path) {
		            	this.$router.push({path: path})
		            }
		        }
		    },

		    /**
		    * Handle a successful search response from the API
		    */
		    errorSearch: function(err) {
		        // display error message
		        this.$store.commit('setLoading', false);	
                this.$store.commit("errorMsg", `There was an API error: ${err.msg}`);
		    },
		},
	}
</script>