<template>
	<v-footer app fixed>

    	<div class="overline">&copy; {{ new Date().getFullYear() }} {{ ($vuetify.breakpoint.smAndDown) ? 'WSU' : 'Western Sydney University' }} | Staff Directory v{{ version }}</div>
		<v-spacer></v-spacer>
		<v-btn text 
			color="#903"
			href="https://wsu.service-now.com/kb_view.do?sysparm_article=KB0016085" 
			target="_blank"
			:small="$vuetify.breakpoint.smAndDown">Knowledge Base</v-btn>

		<v-btn text 
			color="#903"
			href="https://wsu.service-now.com/it" 
			target="_blank"
			:small="$vuetify.breakpoint.smAndDown">IT Service Desk</v-btn>
	</v-footer>
</template>

<script>
	export default {
		name: 'app-footer',
		data : () => ({
			version: process.env.VUE_APP_VERSION
		}),
	}
</script>