<style scoped>
.heading {
	border-bottom: 2px solid #903;
}
</style>
<template>
	<v-container class="pt-5">
		<v-row class="mt-10" justify="center">
			<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>

			<v-col cols="12" md="10">
				<v-card>
					<v-card-title 
					class="heading"
					primary-title>
						{{ heading }}
					</v-card-title>

					<v-card-text>

		        		<v-progress-linear
		        			:active="$store.getters.loading"
							indeterminate
							color="#903"
						></v-progress-linear>

						<slot name="form" class="form-row">
						        
						    
						</slot>

						<v-alert type="info" text>
							PLEASE NOTE: obtaining information from this Directory must be for the legitimate purposes of doing business with and within the
							Western Sydney University and must not be used for unsolicited bulk e-mailing (spamming) or similar purposes.
						</v-alert>

						<v-alert type="info" text>
							Please contact <a href="mailto:itservicedesk@uws.edu.au">IT Service Desk</a> for any queries regarding the Western Sydney University Staff Directory
						</v-alert>
					</v-card-text>
				</v-card>
			</v-col>

			<v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
		</v-row>
	</v-container>
</template>

<script>
	export default {
		name: 'search-modal',
		props: ["heading"]
	}
</script>