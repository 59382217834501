<style>
    * {
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
    }

    

    p a {
        background-color: inherit;
        color: #FF0033 !important;
        text-decoration: none;
    }

    p a:hover {
        color: #000;
        text-decoration: none;
    }

    .bg-dark {
        background-color: #903!important;
    }

    .logo img {
        /*background-color: #990033;*/
        color: inherit;
        height: 52px;
        padding: 9px 15px 0;
    }
</style>
<template>
  <v-app id="inspire">
        <!-- App Nav Header -->
        <AppHeader :links="links.slice(Math.max(links.length - 4, 1))"></AppHeader>

        <AppDrawer app :links="links"></AppDrawer>
        

        <!-- Main Content -->
        <v-content app class="pb-0">
            <router-view></router-view> 
        </v-content>

        <!-- Alert Snackbar -->
        <AppSnackbar></AppSnackbar>


        <AppFooter app></AppFooter>
    </v-app>
</template>

<script>
    import AppHeader from './components/layouts/AppHeader';
    import AppDrawer from './components/layouts/AppDrawer';
    import AppFooter from './components/layouts/AppFooter';
    import AppSnackbar from './components/layouts/AppSnackbar';

    export default {
        name: 'App',

        components: {
            AppHeader,
            AppDrawer,
            AppSnackbar,
            AppFooter
        },
        computed: {
            links() {
                let isLoggedIn = this.$store.getters.isLoggedIn

                return this.headers.filter((l) => { 
                    if (isLoggedIn) {
                        return (l.display != 'logged-out') 
                    } else {
                        return (l.display != 'logged-in')
                    }
                })
            }
        },

        data: () => ({

            headers: [
                {
                    url: '/', 
                    text: 'Home', 
                    icon: 'mdi-magnify'
                },
                {
                    url: '/schools', 
                    text: 'School / Division', 
                    icon: 'mdi-domain'
                },
                {
                    url: '/buildings', 
                    text: 'Buildings', 
                    icon: 'mdi-city',
                    display: 'logged-in'
                },
                {url: '/jp', text: 'JP', icon: 'mdi-gavel'},
                {url: '/interpreters', text: 'Second Language', icon: 'mdi-translate'},
                {
                    click: 'login', 
                    text: 'Login', 
                    icon: 'mdi-account-outline',
                    display: 'logged-out'
                },
                {
                    click: 'logout', 
                    text: 'Log out', 
                    icon: 'mdi-account-lock',
                    display: 'logged-in'
                },
                {
                    href: process.env.VUE_APP_ADMIN_PROFILE, 
                    text: 'Edit', 
                    icon: 'mdi-pencil'
                },
                {
                    href: 'https://wsu.service-now.com/kb_view.do?sysparm_article=KB0016085', 
                    text: 'Help', 
                    icon: 'mdi-help-circle-outline',
                }
                // {
                //     href: '/old/', 
                //     text: 'Legacy View', 
                //     icon: 'mdi-monitor',
                //     display: 'logged-in',
                //     showText: true,
                //     small: true,
                // }
            ]
        }),
    };
</script>
