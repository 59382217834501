import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/search/:criteria/:search',
    name: 'search',
    component: () => import(/* webpackChunkName: "search" */ '../views/StaffSearch.vue')
  },
  {
    path: '/schools',
    name: 'schools',
    component: () => import(/* webpackChunkName: "schools" */ '../views/School.vue')
  },
  {
    path: '/schools/:school/staff',
    name: 'school staff',
    component: () => import(/* webpackChunkName: "schoolstaff" */ '../views/SchoolsDisplay.vue')
  },
  {
    path: '/buildings',
    name: 'buildings',
    component: () => import(/* webpackChunkName: "buildings" */ '../views/Buildings.vue')
  },
  {
    path: '/campus/:campus/building/:building',
    name: 'building directory',
    component: () => import(/* webpackChunkName: "campuses" */ '../views/BuildingDisplay.vue')
  },
  {
    path: '/secure/campus/:campus/building/:building',
    name: 'secure building directory',
    component: () => import(/* webpackChunkName: "campuses" */ '../views/BuildingDisplay.vue')
  },
  {
    path: '/jp',
    name: 'justice of the peace',
    component: () => import(/* webpackChunkName: "justice" */ '../views/Jp.vue')
  },
  {
    path: '/interpreters/:dialect?',
    name: 'interpreters',
    component: () => import(/* webpackChunkName: "campuses" */ '../views/Interpret.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  store.commit("updateStaffMember", {});
  next();
});

export default router
