import axios from 'axios';
import { debugMsg } from './helpers';
const host = process.env.VUE_APP_TELEDEX_API;

/**
* Store the user name in local storage
*/
export function getLocalUser() {
	const userStr = sessionStorage.getItem("user");

	// If no user is stored return null
	if (!userStr || userStr == undefined || userStr == "undefined") { 
		return null; 
	} 

	const user =  JSON.parse(userStr);

	// If user ID token expired return null
	if ((user.idToken.exp < (Date.now()/1000 | 0))) {
		return null;
	}

	return user;
}


/**
* Store the user name in local storage
*/
export function getLocalToken() {
	const toeknStr = sessionStorage.getItem("token");

	// If no user is stored return null
	if (!toeknStr || toeknStr == undefined || toeknStr == "undefined") { 
		return null; 
	} 

	const token =  JSON.parse(toeknStr);

	return token;
}

/**
* Request a list Schools and Departments
*/
export function getPrivateSchools(token) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/private/departments/list`, 
		{
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
		
	})
}




/**
* Request a list of campus building staff from the API
*/
export function getPrivateBuildingStaff(token, campusCode, buildingCode) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/private/staff/campuses/${campusCode}/buildings/${buildingCode}`, {
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}


/**
* Request a list of campus building staff from the API
*/
export function getPremiseBuildingStaff(campusCode, buildingCode) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/private/prem/staff/campuses/${campusCode}/buildings/${buildingCode}`)
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}



/**
* Request the details of a specific staff member
*/
export function getPrivateStaffDetails(token, staffID) {
	console.log("TOKEN")
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/private/staff/${staffID}`, {
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}


/**
* Request the details of a specific staff member
*/
export function getPremiseStaffDetails(staffID) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/private/prem/staff/${staffID}`)
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}


/**
* Request a list of staff that are Justice of the Peace
*/
export function getPrivateJPStaff(token) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/private/staff/jp`, {
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
	})
}


/**
* Request a list of staff that are interpreters
*/
export function getPrivateInterpreters(token, dialect = null) {

	if (dialect == null) {
		return new Promise((res, rej) => {
			axios.get(`${host}/api/v1/private/staff/interpreters`, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`
				}})
				.then((response) => { res(response); })
				.catch((err) => { rej(err); })
		})

	} else {

		return new Promise((res, rej) => {
			axios.get(`${host}/api/v1/private/staff/dialect/${dialect}/interpreters`, {
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`
				}})
				.then((response) => { res(response); })
				.catch((err) => { rej(err); })
		})
	}	
}


/**
* Request a list of staff 
*/
export function getPrivateStaff(token, search, criteria) {
	return new Promise((res, rej) => {

		search = encodeURI(search);

		axios.get(`${host}/api/v1/private/staff/search/${criteria}/${search}`, {
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
		
	})
}


/**
* Request a list of staff based on a school
*/
export function getPrivateSchoolStaff(token, unitCode) {
	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/private/staff/department/${unitCode}`, {
			headers: {
				'Accept': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		})
			.then((response) => { res(response); })
			.catch((err) => { rej(err); })
		
	})
}



export function getLoggedInUser(token) {

	return new Promise((res, rej) => {
		axios.get(`${host}/api/v1/admin/staff/self`,
			{
				headers: {
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			})
			.then((response) => { res(response); })
			.catch((err) => { rej({msg: "There was an error with the API", server: err}); })
		
	})
}