<style scoped>
</style>

<template>
	<div class="text-center ma-2">
		<v-snackbar
			v-model="snackbar"
			bottom
			left
			:color="$store.getters.snackbarColor"
			:timeout="4000">

			{{ $store.getters.snackbarMsg }}

			<v-btn
				dark
	        	text
				@click="snackbar = false"
				>
				Close
			</v-btn>
		</v-snackbar>
	</div>
</template>

<script>
	export default {
		name: 'app-snackbar',
		computed: {
			snackbar: {
				get () {
					return this.$store.getters.showSnackbar
				},

				set (value) {
					this.$store.commit('toggleSnackbar', value)
				}
			}
		}
	}
</script>